/**
 * In charge of core displaying notifications to users which are needed cross app.
 * Currently supports modals and passive notifications
 */

import React, { useEffect, useState, ComponentType } from 'react';
import { useLocation } from 'react-router-dom';

import { DialogManager, Modal } from 'z-frontend-layout';
import { styled } from 'z-frontend-theme';
import { zIndex } from 'z-frontend-theme/utils';

import { Box, TextBlock, TextInline } from 'zbase';
import { switchEmployee, ErrorBoundary } from 'z-frontend-app-bootstrap';
import { DialogProps, ModalFooterProps } from 'z-frontend-overlays';
import { useNotifications } from 'z-frontend-elements';

// We need to bump the z-index to make this work with the boot app. Due to ember legacy reasons the BootApp has a z-index of `tooltip` which is higher than modal
// Since this modal is rendered outside of the boot app container it needs a higher z-index otherwise it will be hidden by the Dashboard
const StyledModalContainer = styled(Box)`
  z-index: ${props => 1 + zIndex('tooltip')(props)};
  position: relative;
`;

type NpsAccountModalProps = { companyName: string; newEmployeeId: string; dialog: DialogProps };
const NpsAccountModal: React.FC<NpsAccountModalProps> = ({ companyName, newEmployeeId, dialog }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Remove pendo id from qs to avoid this showing again if we do a hard transition later
  const onCancel = () => {
    const url = new URL(window.location.href);

    const { searchParams } = url;
    searchParams.delete('pendo');
    url.search = searchParams.toString();

    dialog.close();
    // Use push state to avoid the browser reloading
    window.history.replaceState({}, '', url.toString());
  };

  const footerProps: ModalFooterProps = {
    buttons: [
      { text: 'Take the survey later', onClick: onCancel, mode: 'normal' },
      {
        text: 'Switch account',
        inProgress: isSubmitting,
        onClick: async () => {
          setIsSubmitting(true);
          await switchEmployee(newEmployeeId, true);
          setIsSubmitting(false);
        },
      },
    ],
  };
  return (
    <Modal title="Customer survey" onCancel={onCancel} controlEl={dialog.controlEl} isVisible={dialog.isVisible}>
      <Modal.Body>
        <TextBlock>
          This survey is for your <TextInline bold>{companyName}</TextInline> account. You can switch to this account
          and complete it now, or view it later.
        </TextBlock>
      </Modal.Body>
      <Modal.Footer omitCancelButton {...footerProps} />
    </Modal>
  );
};

type Notification = { modal: ComponentType<any>; type: 'modal' } | { type: 'passiveNotification' };
const notifications: {
  [notificationKey: string]: Notification;
} = {
  npsAccount: { modal: NpsAccountModal, type: 'modal' },
  npsExpired: { type: 'passiveNotification' },
};

enum NotificationName {
  UnableToUpgrade = 'unabletoupgrade',
}

const notificationMessages = {
  [NotificationName.UnableToUpgrade]: 'Unable to perform an upgrade. Please contact support.',
};

// Add more options as we add more modals
type NotificationProps = NpsAccountModalProps;
const SystemNotificationManager: React.FC<{ dialog: DialogProps }> = ({ children, dialog }) => {
  const [notification, setNotification] = useState<Notification | null>(null);
  const [notificationProps, setNotificationProps] = useState<{ [key: string]: NotificationProps }>({});
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const { openNotification: openPassiveNotification } = useNotifications();

  const triggerNotification = (e: CustomEvent) => {
    const { notificationType, props } = e.detail as { notificationType?: string; props?: { [key: string]: any } };
    if (notifications[notificationType]) {
      const notification = notifications[notificationType];
      setNotificationProps(props);
      setNotification(notification);

      if (notification.type === 'passiveNotification') {
        openPassiveNotification(props?.message);
      } else {
        dialog.open();
      }
    }
  };

  const removeNotificationSearchParam = () => {
    const url = new URL(window.location.href);

    const params = new URLSearchParams(window.location.search);
    params.delete('notification');
    url.search = `?${params.toString()}`;
    window.history.replaceState({}, '', url.toString());
  };

  const triggerUrlNotification = (notificationName: string) => {
    const notificationMessage = notificationMessages[notificationName as NotificationName];
    if (notificationMessage) {
      openPassiveNotification(notificationMessage);
      removeNotificationSearchParam();
    }
  };

  useEffect(() => {
    window.document.addEventListener('triggerSystemNotification', triggerNotification);
    triggerUrlNotification(params.get('notification'));
    return () => {
      window.document.removeEventListener('triggerSystemNotification', triggerNotification);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const Modal = notification?.type === 'modal' ? notification.modal : null;
  return (
    <>
      {children}
      <ErrorBoundary FallbackComponent={() => null}>
        {Modal && (
          <StyledModalContainer>
            <Modal onCancel={() => setNotification(null)} {...notificationProps} dialog={dialog} />
          </StyledModalContainer>
        )}
      </ErrorBoundary>
    </>
  );
};

const SystemNotificationManagerContainer: React.FC<{}> = ({ children }) => {
  return (
    <>
      <DialogManager render={dialog => <SystemNotificationManager dialog={dialog} />} />
      {children}
    </>
  );
};

export default SystemNotificationManagerContainer;
