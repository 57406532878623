import { getEventLogger } from './event-logger';

const blacklist = [/^https:\/\/d3sbxpiag177w8\.cloudfront\.net\/Analytics\/UniqueEndUser/];

// For some reason the ts type is missing the disposition prop https://developer.mozilla.org/en-US/docs/Web/API/SecurityPolicyViolationEvent/disposition
export type CSPViolation = SecurityPolicyViolationEvent & { disposition: string };

declare global {
  interface Window {
    cspViolationHandlerRegistered: boolean;
  }
}

// Exported for teting
export function shouldSendError(error: CSPViolation) {
  // Ignore report only. This is triggered by ember and this data is captured in rapid7
  if (error.disposition === 'enforce') {
    const isBlacklisted = blacklist.some(blackListRegex => error.blockedURI.match(blackListRegex));
    return !isBlacklisted;
  }
  return false;
}

export function initializeCspErrorLogger() {
  if (!window.cspViolationHandlerRegistered) {
    // Log csp violations as they don't fire a normal error event https://developer.mozilla.org/en-US/docs/Web/API/SecurityPolicyViolationEvent
    window.addEventListener('securitypolicyviolation', (error: CSPViolation) => {
      // Ignore report only. This is triggered by ember and this data is captured in rapid7
      if (shouldSendError(error)) {
        getEventLogger().logError(
          new Error(`[CSP error]: URI:${error.blockedURI} violated directive:${error.violatedDirective}`),
        );
      }
    });

    window.cspViolationHandlerRegistered = true;
  }
}
