// NOTE: step and guide are arguments provided by pendo
// By convention we'll pass them to all helpers

import gql from 'graphql-tag';

import { getApollo } from '../getApollo';
import { SalesforceAccountOwnerQuery, SalesforceContactQuery } from '../gqlTypes';
import { guides, startGuide } from '../utils/pendoHelpers';

// followed by specific args
function advanceOnClick(step: any, guide: any, selector: string) {
  const target = document.querySelector(selector);

  function unsubscribe() {
    if (target) {
      target.removeEventListener('click', advance);
    }
  }
  function advance() {
    step.advance();
    unsubscribe();
  }

  if (target) {
    target.addEventListener('click', advance);
  }

  // TODO: I have no idea how to unsubscribe without creating a new different :/
  // I think we need to handle cases where the guide is dismissed or advanced
  // window.pendo?.onGuideAdvanced(unsubscribe);
}

export function initializeGlobalUtils(ns: any) {
  ns.advanceOnClick = advanceOnClick;
  ns.sendCustomerReferralSignup = sendCustomerReferralSignup;
  ns.getUpsellOwnerDetails = getUpsellOwnerDetails;
  ns.getQualtricsSurveyLink = getQualtricsSurveyLink;
  ns.startGuide = startGuide;
  // Use zGuides not guides to avoid conflict with native pendo keys.
  ns.zGuides = guides;
}

const sendCustomerReferralMutation = gql`
  mutation SendCustomerReferralMutation {
    sendCustomerReferral {
      success
      exception
    }
  }
`;

export function sendCustomerReferralSignup() {
  const apolloClient = getApollo();
  apolloClient.mutate({ mutation: sendCustomerReferralMutation });
}

const salesforceAccountOwnerQuery = gql`
  query SalesforceAccountOwnerQuery {
    dashboard(id: "me") {
      id
      company {
        id
        salesforceAccount {
          id
          upsellOwner {
            email
            firstName
            lastName
            calendlyLink
          }
        }
      }
    }
  }
`;

const salesforceContactQuery = gql`
  query SalesforceContactQuery {
    dashboard(id: "me") {
      id
      employee {
        id
        salesforceContact {
          id
          qualtricsSurveyLink
        }
      }
    }
  }
`;

export async function getUpsellOwnerDetails() {
  const { data } = await getApollo().query<SalesforceAccountOwnerQuery.Query>({ query: salesforceAccountOwnerQuery });
  return data?.dashboard?.company?.salesforceAccount?.upsellOwner;
}

export async function getQualtricsSurveyLink() {
  const { data } = await getApollo().query<SalesforceContactQuery.Query>({ query: salesforceContactQuery });
  return data?.dashboard?.employee?.salesforceContact?.qualtricsSurveyLink;
}
