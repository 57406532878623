import gql from 'graphql-tag';

import { getApollo } from '../getApollo';
import performThirdPartyInit from './performThirdPartyInit';
import { BoomerangOptions } from '../boomerang';
import { ThirdPartyIntegrationQuery } from '../gqlTypes';

type InitThirdPartyIntegrationsParams = {
  boomerangOptions?: BoomerangOptions;
  disableNice?: boolean;
  enableArkoseForApp?: boolean;
  // FS will always be enabled for trial companies, but this flag can be used to enable for the entire app
  enableFullStoryForApp?: boolean;
  noCompanyInContext?: boolean;
};
const thirdPartyIntegrationQuery = gql`
  query ThirdPartyIntegrationQuery($skipFeaturesQuery: Boolean = false) {
    dashboard {
      id
      company {
        id
        isReal
        prospectAccount {
          type
          demoType
          salesChannel
          consumerEmail
        }
      }
      employee {
        id
        email
        allStatus
        supportId
        isManager
      }
      features @skip(if: $skipFeaturesQuery)
      partnerUserCompanyId
      partnerUserIntegrationHash
      permission
      switches
      userIntegrationHash
    }
    rolesPermissionsData {
      grants
      spans
    }
    inImplementation
    checkoutSession {
      inSession
    }
    switches
  }
`;

export default function initThirdPartyIntegrations(params: InitThirdPartyIntegrationsParams) {
  const { boomerangOptions, disableNice, enableArkoseForApp, enableFullStoryForApp, noCompanyInContext } = params;
  const skipFeaturesQuery = !!noCompanyInContext;
  getApollo()
    .query<ThirdPartyIntegrationQuery.Query, ThirdPartyIntegrationQuery.Variables>({
      query: thirdPartyIntegrationQuery,
      context: { headers: { 'IS-BACKGROUND-QUERY': true } },
      errorPolicy: 'ignore',
      variables: { skipFeaturesQuery },
    })
    .then(result => {
      if (result.errors && result.errors.length !== 0) return;

      performThirdPartyInit({
        boomerangOptions,
        disableNice,
        enableArkoseForApp,
        enableFullStoryForApp,
        noCompanyInContext,
        queryResultData: result.data,
      });
    });
}
