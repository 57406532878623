import React, { ComponentType } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { set } from 'lodash';
// @ts-ignore
import { AppContainer } from 'react-hot-loader';

import { APP_STYLE_ROOT_CLASS } from 'z-frontend-theme';

import createProvidersWrapper from './createProvidersWrapper';
import loadPolyfillsIfNeeded from './polyfills/polyfill-helper';
import { embeddedAppOnlyLogFilter, getEventLogger } from './event-logger';
import ErrorBoundary from './ErrorBoundary';
import AppInit from './app-init/AppInit';
import { setFirstReactRender, BoomerangOptions } from './boomerang';
import { initializeCspErrorLogger } from './cspErrorLogger';
import PrerequisiteManager from './prerequisite/PrerequisiteManager';
import { NICE_LOADER_SCRIPT_ERROR, NICE_LOADER_SCRIPT_ERROR_CONVERTED } from './constants';

/* type NestedReactApps = {
  [key: string]: {
    start: (element?: HTMLElement) => void;
  };
}; */

declare global {
  interface Window {
    React: any;
    __WITHIN_EMBER_APP__: boolean;
  }
}

declare const module: any;

export interface AppSettings {
  App: any;
  element?: HTMLElement;
  providers?: any[];
  hotReloadCallback: Function;
  onBoot?: Function;
  onError?: (error?: any) => void;
  redirectIfTerminated?: boolean;
  disableFirefly?: boolean;
  disableNice?: boolean;
  noCompanyInContext?: boolean;
  enableArkoseForApp?: boolean;
  // FS will always be enabled for trial companies, but this flag can be used to enable for the entire app
  enableFullStoryForApp?: boolean;
  boomerangOptions?: BoomerangOptions;
  eventLoggerParams?: {
    logPageViewsOnlyFromEmbeddedApps?: boolean;
  };
  allowAnonymousUsers?: boolean;
}

// Globals for better dev tools console experience
window.React = React; // for react dev tools

// Log global errors
window.addEventListener('error', errorEvent => {
  const isNiceLoaderScriptError =
    errorEvent.message?.includes(NICE_LOADER_SCRIPT_ERROR) ||
    errorEvent.error?.message?.includes(NICE_LOADER_SCRIPT_ERROR);

  if (isNiceLoaderScriptError) {
    /**
     * For the NICE loader script error which contains a dynamic id in the message, we change it to a static error
     * message to avoid creating a new Sentry alert and PagerDuty incident every couple hours.
     */
    getEventLogger().logError(new Error(NICE_LOADER_SCRIPT_ERROR_CONVERTED));
  } else {
    getEventLogger().logError(errorEvent.error);
  }
});
// Chrome only https://developer.mozilla.org/en-US/docs/Web/Events/unhandledrejection
window.addEventListener('unhandledrejection', (error: PromiseRejectionEvent) => {
  const errorReason = error.reason;
  const isReasonString = typeof errorReason === 'string';
  const isNiceLoaderScriptError =
    (isReasonString && errorReason.includes(NICE_LOADER_SCRIPT_ERROR)) ||
    errorReason?.message?.includes(NICE_LOADER_SCRIPT_ERROR);

  if (isNiceLoaderScriptError) {
    getEventLogger().logError(new Error(NICE_LOADER_SCRIPT_ERROR_CONVERTED));
  } else {
    getEventLogger().logError(errorReason);
  }
});

initializeCspErrorLogger();

const AppWrapper: React.FC<{ settings: AppSettings; ComposedProviders: ComponentType; App: ComponentType }> = ({
  settings,
  App,
  ComposedProviders,
}) => {
  return (
    <div className={APP_STYLE_ROOT_CLASS}>
      <ErrorBoundary onError={settings.onError} isFullscreen>
        <ComposedProviders>
          <>
            <AppInit
              redirectIfTerminated={settings.redirectIfTerminated}
              disableNice={settings.disableNice}
              enableArkoseForApp={settings.enableArkoseForApp}
              boomerangOptions={settings.boomerangOptions}
              enableFullStoryForApp={settings.enableFullStoryForApp}
              noCompanyInContext={settings.noCompanyInContext}
            />
            <App />
          </>
        </ComposedProviders>
      </ErrorBoundary>
    </div>
  );
};

/**
 * This component is exported as renderBaseApp.
 */
export default async function create(settings: AppSettings) {
  const getDefaultElement = () => window.document.getElementById('appRoot');
  const element = settings.element || getDefaultElement(); // TODO: consider passing the element via settings to boot the TNB in the right place
  if (!element && !window.__WITHIN_EMBER_APP__) {
    throw new Error('no DOM element provided or found for app mount');
  }
  const useHotReloading = __DEVELOPMENT__ && settings.hotReloadCallback;
  const ComposedProviders = createProvidersWrapper([
    useHotReloading && AppContainer,
    ...(settings.providers || []),
    (!window.__WITHIN_EMBER_APP__ || __APP_NAME__ === 'boot') && PrerequisiteManager, // Must come after other providers to be inside Apollo context
  ]);

  const polyfillPromise = loadPolyfillsIfNeeded();

  polyfillPromise.then(() => {
    getEventLogger().log('App booted');
    if (settings.onBoot) {
      settings.onBoot();
    }
  });

  if (settings.eventLoggerParams && settings.eventLoggerParams.logPageViewsOnlyFromEmbeddedApps) {
    getEventLogger().addLogFilter(embeddedAppOnlyLogFilter);
  }

  const renderApp = (App: ComponentType, el?: HTMLElement, callback?: (value?: unknown) => void) =>
    render(<AppWrapper settings={settings} App={App} ComposedProviders={ComposedProviders} />, el || element, () => {
      setFirstReactRender(Date.now());
      if (callback) {
        callback();
      }
    });

  if (window.__WITHIN_EMBER_APP__) {
    set(window, `embeddedReactApps.${__APP_NAME__}.start`, async (element?: HTMLElement) => {
      await polyfillPromise;
      const el = element || getDefaultElement();

      if (!el) {
        throw new Error('no element found for render');
      }

      renderApp(settings.App, el);
      if (useHotReloading && module.hot) {
        const _renderApp = (App: ComponentType) => {
          renderApp(App, el);
        };
        settings.hotReloadCallback(_renderApp);
      }

      return new Promise(resolve => {
        renderApp(settings.App, el, resolve);
      });
    });

    set(window, `embeddedReactApps.unmount`, (element?: HTMLElement) => {
      if (element) {
        unmountComponentAtNode(element);
      }
    });
  } else {
    await polyfillPromise;
    if (useHotReloading && module.hot) {
      settings.hotReloadCallback(renderApp);
    }

    renderApp(settings.App);
  }
}
