import React, { FunctionComponent } from 'react';

import { Icon } from 'zbase';
import { styled } from 'z-frontend-theme';
import { color } from 'z-frontend-theme/utils';

import { useToggleChatWidget } from './utils/toggleChatWidget';

const ChatWidgetButton = styled('button')`
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 1900000001;
  height: 60px;
  width: 60px;
  border-radius: 6px;
  border: 0px;
  background-color: ${color('secondary.a')};

  cursor: pointer;
  &:hover {
    background-color: ${color('button.defaultActive')};
  }
`;

const ChatWidget: FunctionComponent = () => {
  const [isButtonOpen] = useToggleChatWidget();
  return (
    <>
      <ChatWidgetButton
        onClick={() => window.document.dispatchEvent(new CustomEvent('toggleSupportFlow'))}
        bg="secondary.a"
      >
        <Icon iconName={isButtonOpen ? 'close-circle' : 'comments'} s="xlarge" color="grayscale.white" />
      </ChatWidgetButton>
    </>
  );
};

export default ChatWidget;
