import { getEventLogger } from '../event-logger';
import { onProdHost } from '../utils/urlUtils';

declare global {
  interface Window {
    setupEnforcement: any;
  }
}

const arkosePublicApi = onProdHost()
  ? 'https://client-api.arkoselabs.com/v2/A515BA54-5E55-4708-88F6-E0CCC8EB9A23/api.js'
  : 'https://client-api.arkoselabs.com/v2/11111111-1111-1111-1111-111111111111/api.js';

type ArkoseConfig = {
  onCompleted(t: { token?: string }): void;
  onFailed(t: { token?: string }): void;
  onHide(): void;
  onReady(): void;
  onReset(): void;
  onShow(): void;
  onShown(): void;
  onSupress(): void;
  onError(response: { error?: string }): void;
};

let Arkose: {
  setConfig: (configuration: ArkoseConfig) => void;
  run: () => void;
};

export const setupEnforcement = (enforcement: any) => {
  Arkose = enforcement;
};

window.setupEnforcement = setupEnforcement;

let arkoseLoaded = false;
// by default we consider it disable until we call bootArkose
let arkoseDisabled = true;

export enum CaptchaStatus {
  // the user completed the challenge or Arkose determined it wasn't needed
  completed,
  // the user dismissed the dialog
  dismissed,
  // Arose is either disabled or arkose-boot wasn't called and the extension didn't load
  notRequired,
}

export const captcha = () => {
  return new Promise<{ token?: string; status: CaptchaStatus }>((resolve, reject) => {
    const logger = getEventLogger();
    if (arkoseDisabled) {
      logger.log('arkose-validation-at-checkout', {
        message: `Arkose is disabled or boot wasn't called, bypassing`,
      });
      resolve({ token: undefined, status: CaptchaStatus.notRequired });
      return;
    }

    if (arkoseLoaded) {
      Arkose.run();
    }

    Arkose.setConfig({
      // callback documentation: https://developer.arkoselabs.com/docs/standard-setup
      onReady() {
        // Callback function invoked when the Enforcement is ready. The Enforcement cannot be triggered before this event. You may want to disable the UI you are protecting until this event has been triggered.
        logger.log('arkose-validation-at-checkout', { message: `arkose: onReady` });
        arkoseLoaded = true;
        Arkose.run();
      },
      onCompleted(response: { token?: string }) {
        logger.log('arkose-validation-at-checkout', { message: `arkose: onCompleted. token: ${response.token}` });
        resolve({ token: response.token, status: CaptchaStatus.completed });
      },
      onFailed(response: { token?: string }) {
        // Invoked when a challenge has failed (the user has failed the challenge multiple times and is not allowed to continue the session).
        logger.log('arkose-validation-at-checkout', { message: `arkose: onFailed. token ${response.token}` });
        reject();
      },
      onError(response: { error?: string }) {
        // A callback invoked when an error occurs in the loading of the challenge.
        logger.log('arkose-validation-at-checkout', { message: `arkose: onError. error: ${response.error}` });
        reject();
      },
      // Just logging
      onHide() {
        // Callback function invoked when the EC is hidden. For example, this happens after an EC is completed or if the user clicks the close button. Note that the close button only appears when in Lightbox mode.
        logger.log('arkose-validation-at-checkout', { message: `arkose: onHide` });
        resolve({ token: undefined, status: CaptchaStatus.dismissed });
      },
      onReset() {
        // A callback invoked after the Enforcement resets. Typically occurs after a challenge has been successfully answered.
        logger.log('arkose-validation-at-checkout', { message: `arkose: onReset` });
      },
      onShow() {
        // A callback invoked when the Enforcement is running and Arkose Detect is analyzing the user intent. The onShow method will also be invoked when an Enforcement Challenge is re-displayed (e.g. if the user closes the EC and tries to continue). Note that the close button only appears when in Lightbox mode.
        logger.log('arkose-validation-at-checkout', { message: `arkose: onShow` });
      },
      onShown() {
        // A callback invoked when the Enforcement Challenge is displayed. The onShown method will only be invoked the first time an Enforcement Challenge is displayed.
        logger.log('arkose-validation-at-checkout', { message: `arkose: onShown` });
      },
      onSupress() {
        // A callback invoked when the an Enforcement Challenge is suppressed (i.e. A session was classified as not requiring a challenge).
        logger.log('arkose-validation-at-checkout', { message: `arkose: onSupress` });
      },
    });
  });
};

export const bootArkose = ({ switches, enableArkoseForApp }: { switches: any; enableArkoseForApp?: boolean }) => {
  if (__DEVELOPMENT__ && !__ENABLE_ARKOSE_LOCALLY__) {
    return;
  }
  if (switches.arkose_killswitch) return;

  if (enableArkoseForApp && switches.arkose_captcha_at_submit_checkout) {
    arkoseDisabled = false;
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = arkosePublicApi;
    script.setAttribute('data-callback', 'setupEnforcement');
    script.async = true;
    script.defer = true;
    script.id = 'arkose_script';
    window.document.head.append(script);
  }
};
