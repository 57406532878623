/**
 * We have two projects in Optimizely: "Zenefits" and "Demo Center".
 *
 * This is the project ID for "Zenefits".
 */
const projectId = '2237140475';

const bootOptimizely = () => {
  const scriptElement = document.createElement('script');
  scriptElement.async = true;
  scriptElement.src = `https://cdn.optimizely.com/js/${projectId}.js`;
  const firstScript = document.getElementsByTagName('script')[0];
  firstScript.parentNode?.insertBefore(scriptElement, firstScript);
};

type BootOptimizelyParams = {
  switches: null | { optimizely_killswitch: boolean };
  isCustomerDemo: boolean;
};

const shouldBootOptimizely = ({ switches, isCustomerDemo }: BootOptimizelyParams): boolean => {
  if (switches?.optimizely_killswitch) return false;
  if (__DEVELOPMENT__ && !__ENABLE_OPTIMIZELY_LOCALLY__) return false;
  // Already booted.
  if (window.optimizely) return false;

  // Use Optimizely in demo experience and checkout app.
  if (isCustomerDemo || __APP_NAME__ === 'checkout') return true;

  return false;
};

/**
 * Boot Optimizely if required conditions are met.
 */
export const checkAndBootOptimizely = (params: BootOptimizelyParams) => {
  if (shouldBootOptimizely(params)) {
    bootOptimizely();
  }
};
