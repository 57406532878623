import moment from 'moment';

import 'z-frontend-pendo';

import { getEventLogger } from '../event-logger';
import runPendoSnippet from '../pendoSnippet';
import { generatePendoIds, has401k, parseFieldsFromQS } from './utils';
import { EmployeeStatus } from '../gqlTypes';
import sanitizeUrl from './sanitizeUrl';
import evaluateNpsMessage from './evaluateNpsMessage';
import { initializeGlobalUtils } from './global-utils';
import registerGuideEvents from './registerGuideEvents';
import { getSubscriptionSkus, getZappInstallations } from '../storeSubscriptionInfo';
import {
  buildExperimentValuesFromSwitches,
  buildTruthyTraitsFromExperimentSwitches,
} from '../utils/getExperimentTraits';

type SegmentationOptions = {
  employeeId: string;
  isAdmin?: boolean | null;
  isManager?: boolean | null;
  isAnonymousUser?: boolean;
  isSalesDemoCompany?: boolean | null;
  isSelfServe: boolean;
  isDemoCompany?: boolean | null;
  isFreeLimitedTrialCompany?: boolean | null;
  employeeStatus?: EmployeeStatus | null;
  switches: { [switchName: string]: boolean };
  grants?: JSON;
};

type BootPendoArgs = {
  companyId: string;
  employeeId: string;
  userIntegrationHash: string;
  supportId?: string | null;
  isRealCompany?: boolean | null;
  isFreeLimitedTrialOrCustomerDemo?: boolean;
  partnerUser?: {
    userIntegrationHash: string;
    companyId: string;
  } | null;
} & SegmentationOptions;

const PENDO_API_KEY = 'cb5a2f79-52e7-4480-7879-39bf72633a50';
const PENDO_DEV_API_KEY = 'd1d88262-953d-4cf5-61c9-4232b7e23280';

/**
 * Parses the url for extra segmentation data in qs. Currently only implemented for last_survey_sent
 */
export function getSegmentationFieldsFromUrl(location: Location, options: { employeeId: string }) {
  const qs = parseFieldsFromQS(location);

  // Explicitly adding agent to the field name as there is also a lastSurveySent date synced from SFDC
  const segmentationFields = { visitor: {}, account: {} } as {
    visitor: { lastSurveySentAgent?: string; npsProgramTypeAgent?: string };
    account: {};
  };
  // Require employeeId in qs matches current employeeId to avoid multi EIN cases where we'd set last_survey_sent for the wrong employee
  if (qs.get('last_survey_sent') && qs.get('employeeId') && qs.get('employeeId') === options.employeeId) {
    const lastSurveySentAgent = moment.utc(qs.get('last_survey_sent') as string, 'YYYY-MM-DD').startOf('day');

    // Only update this value when the survey is active (ie: within last 14 days). Otherwise if someone clicked on an old link for some reason they'd be set in the wrong program and possibly overwrite a newer lastSurveySent date
    if (lastSurveySentAgent && moment().diff(lastSurveySentAgent, 'days') < 14) {
      segmentationFields.visitor.lastSurveySentAgent = lastSurveySentAgent.toISOString();

      const npsProgramTypeAgent = qs.get('nps_program_type');
      if (npsProgramTypeAgent) {
        segmentationFields.visitor.npsProgramTypeAgent = npsProgramTypeAgent;
      }
    }
  }
  return segmentationFields;
}

export default async ({
  companyId,
  supportId,
  userIntegrationHash,
  partnerUser,
  isRealCompany,
  isFreeLimitedTrialOrCustomerDemo,
  ...segmentationOptions
}: BootPendoArgs) => {
  const { switches } = segmentationOptions;
  const useDevSubscription = switches.pendo_dev_subscription;
  const switchesEnablePendo = !switches.pendo_killswitch && switches.pendo_release;
  // Include real companies, trial/demo companies, and some whitelisted test companies
  const applyDemoIdPrefix = !isRealCompany && !isFreeLimitedTrialOrCustomerDemo;
  const anonymousOrHasId = segmentationOptions.isAnonymousUser || !!userIntegrationHash;
  const shouldBootPendoForCompany = anonymousOrHasId && switchesEnablePendo;
  const isCypressGuideTest = !!(window.Cypress && window.isCypressGuideTest);

  // Already initialized pendo, avoid booting it a second time
  if (window.pendo) return;
  // If this is a Cypress Guide test it overrides the dev and company conditions
  if (!isCypressGuideTest) {
    // if ths is cypress test, supress pendo window
    if (window.Cypress) return;
    if (!shouldBootPendoForCompany) return;
    if (!__ENABLE_PENDO_LOCALLY__ && __DEVELOPMENT__) return;
  }

  try {
    init(
      userIntegrationHash,
      companyId,
      segmentationOptions,
      supportId,
      partnerUser,
      isCypressGuideTest,
      applyDemoIdPrefix,
      useDevSubscription,
    );
  } catch (err) {
    err.message = `Failed to load Pendo with error: ${err.message}`;
    getEventLogger().logError(err);
  }
};

enum TrialType {
  SALES = 'SALES_DEMO',
  SELF_SERVE = 'SELF_SERVE_DEMO',
  TRIAL = 'SELF_SERVE_TRIAL',
}

function getTrialType(segmentationOptions: SegmentationOptions): TrialType | null {
  if (segmentationOptions.isSalesDemoCompany) {
    return TrialType.SALES;
  } else if (segmentationOptions.isDemoCompany) {
    // It's a demo, but not a sales demo, so it's a customer demo.
    // It's not necessary in self-serve channel. It could be in sales-assisted channel.
    // This trial type naming is a bit misleading.
    return TrialType.SELF_SERVE;
  } else if (segmentationOptions.isFreeLimitedTrialCompany) {
    return TrialType.TRIAL;
  }

  return null;
}

function onGuidesLoaded(useDevSubscription: boolean, companyId: string) {
  if (window.pendo) {
    window.pendo.guidesReady = true;
  }
  window.document.dispatchEvent(new CustomEvent('pendo_guides_loaded'));
  evaluateNpsMessage(useDevSubscription, companyId);
}

// Filter out any keys where the value is undefined to satisfy types which don't allow undefineds
function buildMetadata(metadata: { [key: string]: string | number | boolean | string[] | null | undefined }) {
  return Object.keys(metadata).reduce((obj, key) => {
    const val = metadata[key];
    if (val !== undefined) {
      obj[key] = val;
    }
    return obj;
  }, {} as { [key: string]: string | number | boolean | string[] | null });
}

async function init(
  userIntegrationHash: string,
  companyId: string,
  segmentationOptions: SegmentationOptions,
  supportId?: string | null,
  partnerUser?: {
    userIntegrationHash: string;
    companyId: string;
  } | null,
  isCypressGuideTest?: boolean,
  applyDemoIdPrefix?: boolean,
  useDevSubscription?: boolean,
) {
  runPendoSnippet(useDevSubscription ? PENDO_DEV_API_KEY : PENDO_API_KEY);

  const { isAdmin, employeeStatus, switches, employeeId, isManager } = segmentationOptions;
  const pendoIds = generatePendoIds(
    partnerUser ? partnerUser.userIntegrationHash : userIntegrationHash,
    partnerUser ? partnerUser.companyId : companyId,
    applyDemoIdPrefix || false,
  );

  const activeSwitches = Object.keys(switches).filter(switchKey => (switches as { [key: string]: boolean })[switchKey]);

  if (window.pendo) {
    window.pendo.guidesReady = false;
  }

  const segmentationFieldsFromUrl = getSegmentationFieldsFromUrl(window.location, { employeeId });
  const grantKeys = Object.keys(segmentationOptions.grants || {});
  const subscriptionSkus = await getSubscriptionSkus(isAdmin);
  const zappInstallations = await getZappInstallations(isAdmin);

  const experimentTraitsFromSwitches = buildExperimentValuesFromSwitches(switches);
  // Only send when true. If we don't do this we'll override the value with False after the experiment is completed and we turn the switch off
  const truthyTraitsFromSwitches = buildTruthyTraitsFromExperimentSwitches(experimentTraitsFromSwitches);

  window.pendo?.initialize({
    sanitizeUrl: (url: string) => sanitizeUrl(url, window.location.href),
    excludeAllText: true,
    visitor: buildMetadata({
      isManager,
      activeSwitches,
      supportId,
      isAdmin,
      employeeStatus,
      id: pendoIds.visitorId,
      isAnonymousUser: !!segmentationOptions.isAnonymousUser,
      anonymousId: segmentationOptions.isAnonymousUser ?? getEventLogger().uiEventLogger.anonymousId,
      ...window.pendoSegmentationOverrides?.visitor,
      ...segmentationFieldsFromUrl.visitor,
      isFullCompanyAdmin: grantKeys.includes('is_full_company_admin'),
      isPayrollAdmin: grantKeys.includes('is_payroll_admin'),
      isBenefitsAdmin: grantKeys.includes('is_benefits_admin'),
      ...truthyTraitsFromSwitches,
    }),

    account: buildMetadata({
      subscriptionSkus,
      id: pendoIds.accountId,
      has401k: has401k(zappInstallations),
      trialType: getTrialType(segmentationOptions),
      isSelfServe: segmentationOptions.isSelfServe,
      ...window.pendoSegmentationOverrides?.account,
      ...segmentationFieldsFromUrl.account,
    }),
    guides: {
      disable: (segmentationOptions.isSalesDemoCompany || window.Cypress) && !isCypressGuideTest,
    },
    events: {
      ready() {
        window.document.dispatchEvent(new CustomEvent('pendo_ready'));
      },
      guidesLoaded() {
        onGuidesLoaded(!!useDevSubscription, companyId);
        registerGuideEvents(window.pendo);
      },
    },
  });
  initializeGlobalUtils(window.pendo);
}
