import fetchWrapper from '../fetchWrapper';
import getCookie from './get-cookie';
import { getEventLogger } from '../..';

export default async function(employeeId: string, retainSearch?: boolean) {
  const headers: { [key: string]: string } = {
    'Content-Type': 'application/x-www-form-urlencoded',
  };

  const csrfMiddlewareToken = getCookie('csrftoken');
  if (csrfMiddlewareToken) {
    headers['X-CSRFToken'] = csrfMiddlewareToken;
  }

  try {
    const response = await fetchWrapper('/companySelector/', {
      method: 'POST',
      body: `accountAction=select&employeeId=${employeeId}`,
      headers: new Headers(headers),
    });

    if (!response.ok) {
      throw new Error(`Redirect to dashboard error: ${response.statusText}`);
    }
    const baseUrl = response.url || 'dashboard/';
    window.location.href = retainSearch ? `${baseUrl}${window.location.search}` : baseUrl;
  } catch (e) {
    getEventLogger().logError(e);
  }
}
