function getEventTypeForButtonCick(btn: HTMLButtonElement) {
  if (btn.classList.contains('_pendo-guide-next_')) {
    return 'advance';
  } else if (btn.classList.contains('_pendo-close-guide_')) {
    return 'dismiss';
  } else {
    // Assume it's a link if not advanced or close
    return 'link';
  }
}

function getStyleForButtonCick(btn: HTMLButtonElement) {
  if (btn.classList.contains('_pendo-button-primaryButton')) {
    return 'primary';
  } else if (btn.classList.contains('_pendo-button-secondaryButton')) {
    return 'secondary';
  } else if (btn.classList.contains('_pendo-button-tertiaryButton')) {
    return 'tertiary';
  }
}

export default function(pendo: Window['pendo']) {
  if (pendo) {
    pendo.events.on('validateGuide', (guideContent: string, guide: PendoGuide) => {
      try {
        // Track that guide will be shown
        window.analytics?.track('pendoGuideSeen', {
          guideName: guide.name,
          guideId: guide.id,
        });

        // Register click handler which will send events based on any interactions with CTA's
        guide.steps.forEach((step, i) => {
          const originalScript = step.script;
          step.script = (step: PendoGuideStep, guide: PendoGuide) => {
            originalScript(step, guide);
            step &&
              step.attachEvent(step.guideElement[0], 'click', e => {
                if (e.target && (e.target as HTMLElement).tagName === 'BUTTON') {
                  const button = e.target as HTMLButtonElement;
                  const label = button?.textContent;

                  window.analytics?.track('pendoGuideInteraction', {
                    label,
                    guideName: guide.name,
                    guideId: guide.id,
                    stepId: step.id,
                    stepNumber: i + 1,
                    style: getStyleForButtonCick(button),
                    type: getEventTypeForButtonCick(button),
                  });
                }
              });
          };
        });
        // eslint-disable-next-line no-empty
      } catch (e) {}

      return true;
    });
  }
}
