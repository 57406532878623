import { getEventLogger } from '../event-logger';

const urlAllowList = [/\/app\/well-being\/#\/article\/\d/];
const queryParamAllowList = ['category', 'page', 'utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];
const blackholeDenylist = [/\/dashboard\/#\/redirect-to-route/];

function isPathAllowListed(url: URL, hashPrefix: string) {
  const pathAndHashPrefix = `${url.pathname}#${hashPrefix}`;
  return urlAllowList.some(regex => pathAndHashPrefix.match(regex));
}

function sanitizePath(parsedUrl: URL) {
  // Remove leading #
  const newPath = parsedUrl.hash
    .slice(1)
    .split('/')
    .reduce((sanitizedPathComponents, pathComponent) => {
      if (
        pathComponent &&
        pathComponent.match(/^\d+$/) &&
        !isPathAllowListed(parsedUrl, [...sanitizedPathComponents, pathComponent].join('/'))
      ) {
        sanitizedPathComponents.push('(id)');
      } else {
        sanitizedPathComponents.push(pathComponent);
      }
      return sanitizedPathComponents;
    }, [] as string[])
    .join('/');
  parsedUrl.hash = newPath;

  return parsedUrl;
}

function sanitizeQueryString(url: URL) {
  const { searchParams } = url;

  searchParams.forEach((value, key) => {
    if (!queryParamAllowList.includes(key)) {
      searchParams.set(key, '(scrubbed)');
    }
  });

  url.search = decodeURI(searchParams.toString());
  return url;
}

// For paths we want to ignore set the domain to a value 'blackhole.zeneftis.com' which Pendo will ignore in stats
function blackHoleDenyListedPaths(url: URL) {
  const pathAndHashPrefix = `${url.pathname}${url.hash}`;
  const matchesDenylist = blackholeDenylist.some(regex => pathAndHashPrefix.match(regex));
  if (matchesDenylist) {
    url.hostname = 'blackhole.zenefits.com';
  }
  return url;
}

// https://stackoverflow.com/questions/10687099/how-to-test-if-a-url-string-is-absolute-or-relative
function isUrlAbsolute(url: string) {
  if (url.indexOf('//') === 0) {
    return true;
  } // URL is protocol-relative (= absolute)
  if (url.indexOf('://') === -1) {
    return false;
  } // URL has no protocol (= relative)
  if (url.indexOf('.') === -1) {
    return false;
  } // URL does not contain a dot, i.e. no TLD (= relative, possibly REST)
  if (url.indexOf('/') === -1) {
    return false;
  } // URL does not contain a single slash (= relative)
  if (url.indexOf(':') > url.indexOf('/')) {
    return false;
  } // The first colon comes after the first slash (= relative)
  if (url.indexOf('://') < url.indexOf('.')) {
    return true;
  } // Protocol is defined before first dot (= absolute)
  return false; // Anything else must be relative
}

function evaluteUrlPartsToReturn(updatedUrl: URL, originalUrl: string) {
  // Full url
  if (isUrlAbsolute(originalUrl)) {
    return updatedUrl.toString();
  } else if (originalUrl.startsWith('#')) {
    // Just a fragment to return
    return updatedUrl.hash;
  } else {
    // relative path
    return `${updatedUrl.pathname}${updatedUrl.search}${updatedUrl.hash}`;
  }
}

// The url may be a relative path so use the current url as a base
export default function sanitizeUrl(url: string, baseUrl: string) {
  try {
    let parsedUrl = new URL(url, baseUrl);

    parsedUrl = sanitizePath(parsedUrl);
    parsedUrl = sanitizeQueryString(parsedUrl);
    parsedUrl = blackHoleDenyListedPaths(parsedUrl);

    return evaluteUrlPartsToReturn(parsedUrl, url);
  } catch (e) {
    getEventLogger().logError(e);
    return url;
  }
}
