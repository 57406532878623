import gql from 'graphql-tag';

import { parseFieldsFromQS } from './utils';
import { getApollo } from '../getApollo';
import { ValidCompaniesQuery } from '../gqlTypes';

const npsSurveyIds = {
  dev: ['gx-dcFR9-vu8V6kgZMjBoXsFGdY'],
  production: ['C0Xy8mSjIeo0lntrTv9JCDNrHvo', 'y8SKbcgbz8aAtDt1s2gMe65PQbk'],
};

const validCompaniesQuery = gql`
  query ValidCompaniesQuery {
    companyHubCompanies {
      companyId
      employeeId
      name
    }
  }
`;

export default async function(useDevSubscription: boolean, companyId: string) {
  const qs = parseFieldsFromQS(window.location);
  const environmentNpsSurveyIds = useDevSubscription ? npsSurveyIds.dev : npsSurveyIds.production;
  const pendoIdFromQs = qs.get('pendo');
  if (pendoIdFromQs && environmentNpsSurveyIds.includes(pendoIdFromQs) && !window.pendo?.findGuideById(pendoIdFromQs)) {
    let event;
    if (qs.get('companyId') === companyId) {
      // If the link was for the current company we know it's expired or already answered
      event = new CustomEvent('triggerSystemNotification', {
        detail: {
          notificationType: 'npsExpired',
          props: { message: 'This survey has expired.' },
        },
      });
    } else if (qs.get('companyId')) {
      const { data } = await getApollo().query<ValidCompaniesQuery.Query>({
        query: validCompaniesQuery,
        fetchPolicy: 'cache-first',
      });

      const matchedCompany = data.companyHubCompanies.find(company => company.companyId === qs.get('companyId'));
      if (matchedCompany) {
        event = new CustomEvent('triggerSystemNotification', {
          detail: {
            notificationType: 'npsAccount',
            props: { companyName: matchedCompany.name, newEmployeeId: matchedCompany.employeeId },
          },
        });
      }
    }

    if (event) {
      window.document.dispatchEvent(event);
    }
  }
}
