import fullstorySnippet from './fullstorySnippet';

type Traits = { [key: string]: any };
declare global {
  interface Window {
    FS?: {
      identify: (id: string, values?: Traits) => void;
      event: (eventName: string, values?: Traits) => void;
      setUserVars: (values?: Traits) => void;
      restart: () => void;
      shutdown: () => void;
    };
    _fs_ready?: () => void;
  }
}

export enum FullStoryControlLevel {
  Null = 0,
  Route = 1,
  App = 2,
  User = 3,
}

const fullstoryOrgs: { [key: string]: string } = {
  'secure.zenefits.com': 'E2AHG', // marketing's account
  // default: 'QJ3A3', // Miguel's trial account
  default: 'E2AHG', // Using the same marketing account (recording is disabled from localhost and Squamish)
};

export const fullStoryState: {
  /**
   * FullStoryControlLevel.User means FullStory is booted for the user.
   * FullStoryControlLevel.App means FullStory is booted for the whole z-frontend app.
   * FullStoryControlLevel.Route means FullStory is booted for the specific route.
   *
   * If it's booted for the user, generally we shouldn't shutdown it from an app or a route level.
   * If it's booted for the app, generally we shouldn't shutdown it from a route level.
   */
  enabledFor: FullStoryControlLevel;
} = {
  enabledFor: FullStoryControlLevel.Null,
};

interface BootFullstoryArgs {
  userIntegrationHash?: string | null;
  trialAccountEmail?: string | null;
  isAnonymous?: boolean;
  switches: any;
  // Whether FullStory should be enabled for the whole z-frontend app.
  enableFullStoryForApp?: boolean;
  // Whether Fullstory should be enabled for a specific route.
  isEnabledForRoute?: boolean;
  isFreeLimitedTrialCompany?: boolean;
  isFreeLimitedTrialOrCustomerDemo?: boolean;
}

export default async function bootFullstory({
  userIntegrationHash,
  trialAccountEmail,
  isAnonymous,
  isFreeLimitedTrialCompany,
  enableFullStoryForApp,
  isEnabledForRoute,
  isFreeLimitedTrialOrCustomerDemo,
  switches,
}: BootFullstoryArgs) {
  if (__DEVELOPMENT__ && !__ENABLE_FULLSTORY_LOCALLY__) {
    return;
  }

  if (window.__WITHIN_EMBER_APP__ && __APP_NAME__ !== 'boot') {
    // for embedded apps (and Ember routes) we rely on boot (dashboard) to load this
    // so org-chart doesn't need to load it again
    return;
  }

  if (switches.fullstory_killswitch) {
    return;
  }
  // If not anonymous we need some identifier
  const id = trialAccountEmail || userIntegrationHash;
  if (!isAnonymous && !id) {
    return;
  }

  // Boot Fullstory only if explicitly enabled for the app, for a specific route, or
  // for all freeLimitedTrial or customer demo accounts, i.e. excluding sales demo accounts.
  if (enableFullStoryForApp || isEnabledForRoute || isFreeLimitedTrialOrCustomerDemo) {
    const org = fullstoryOrgs[window.location.hostname] || fullstoryOrgs.default;
    fullstorySnippet(org);

    // Update fullStoryState.enabledFor
    let newEnabledFor: FullStoryControlLevel = FullStoryControlLevel.Route;
    if (isFreeLimitedTrialOrCustomerDemo) {
      newEnabledFor = FullStoryControlLevel.User;
    } else if (enableFullStoryForApp) {
      newEnabledFor = FullStoryControlLevel.App;
    }
    if (newEnabledFor > fullStoryState.enabledFor) {
      fullStoryState.enabledFor = newEnabledFor;
    }

    const traits = {
      isFreeLimitedTrialCompany,
      isEmbeddedNativeView:
        window.ZENEFITS_MOBILE_INTEGRATION && window.ZENEFITS_MOBILE_INTEGRATION.isEmbeddedNativeView,
    };
    // NOTE: We only call identify if we have an ID
    if (isAnonymous) {
      window.FS?.setUserVars(traits);
    } else {
      window.FS?.identify(id as string, traits);
    }
  }
}

export const shutdownFullStory = (shutdownFor: FullStoryControlLevel) => {
  // FullStoryControlLevel enum values are ordered by priority.
  if (shutdownFor >= fullStoryState.enabledFor) {
    window.FS?.shutdown();
  }
};
