import { useEffect, useState } from 'react';

export function useToggleChatWidget() {
  const [isButtonOpen, setIsButtonOpen] = useState(false);

  function triggerSupportFlow(page?: string | null, options?: { solvvyVersion?: 'v4' | 'v5' }) {
    // We lookup height of the container so we can match it
    const solvvyContainer =
      options?.solvvyVersion === 'v5'
        ? document.getElementById('solvvy-v4-container')
        : document.getElementById('solvvy-v5-ui');

    const event = new CustomEvent('triggerSupportFlow', {
      detail: {
        page,
        solvvyVersion: options?.solvvyVersion,
        closeSolvvyWindow: closeSupportFlow,
        containerHeight: solvvyContainer && solvvyContainer.clientHeight,
      },
    });

    window.document.dispatchEvent(event);
  }

  function closeSupportFlow() {
    const event = new CustomEvent('closeSupportFlow');
    window.document.dispatchEvent(event);
  }

  const solvvyVersion = 'v5';

  useEffect(() => {
    function toggleButton() {
      if (isButtonOpen) {
        closeSupportFlow();
        setIsButtonOpen(false);
      } else {
        triggerSupportFlow(null, { solvvyVersion });
        setIsButtonOpen(true);
      }
    }
    window.document.addEventListener('toggleSupportFlow', toggleButton);

    return () => {
      window.document.removeEventListener('toggleSupportFlow', toggleButton);
    };
  });
  return [isButtonOpen, setIsButtonOpen];
}
